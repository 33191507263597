 <template>
  <section class="mb-8">
    <div class="flex items-center justify-between mb-2">
      <h3 class="text-2xl">
        Items Assigned
      </h3>
      <vue-button
        v-if="edit"
        :icon="['fas', 'plus-circle']"
        text
        @click="addItem"
      />
    </div>
    <div v-if="model.items && model.items.length > 0" class="flex flex-wrap w-row-offset -mx-2">
      <draggable-items v-model="itemsSorted" :edit="edit" :model="model" :remove-item="removeItem" class="w-full mt-10"/>
    </div>
    <div v-else class="text-gray-600 text-sm py-4">
      No menu items have been assigned yet. Click the edit button to add items.
    </div>
  </section>
</template>
<script>
import { map, orderBy, cloneDeep } from 'lodash'
import draggableItems from './menus/DraggableItems'

export default {
  components: {
    draggableItems
  },

  props: {
    model: {
      type: [Boolean, Object],
      required: false,
      default: () => { return { items: [] } }
    },

    edit: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    itemsSorted: {
      get () {
        return this.orderItems(this.model.items)
      },
      set (value) {
        this.$emit('update:model', {
          ...this.model,
          ...{
            items: map(value, (item, index) => {
              item.order = index
              return item
            })
          }
        })
      }
    }
  },

  methods: {
    orderItems (array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].children && array[i].children.length) {
          array[i].children = this.orderItems(array[i].children)
        }
      }
      return orderBy(array, 'order')
    },
    addItem () {
      this.model.items.push({
        id: null,
        menu_id: this.$route.params.model,
        label: 'No Label',
        slug: '/',
        order: this.model.items.length,
        children: []
      })
    },
    recursiveRemove (list, id) {
      return list.map(item => { return { ...item } }).filter(item => {
        if ('children' in item) {
          item.children = this.recursiveRemove(item.children, id)
        }
        return item.id !== id
      })
    },
    removeItem (id) {
      const model = cloneDeep(this.model)
      model.items = this.recursiveRemove(model.items, id)
      this.$emit('update:model', model)
    }
  }
}
</script>
