<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.primary"
        :value="model.primary"
        :edit="edit"
        type="checkbox"
        class="mb-2"
        label="Primary Menu?"
      />
    </template>
    <template #main>
      <LocaleSwitcher
        :edit="edit"
        @switch="getModel"
      />
      <NavItemsAssigned
        :model.sync="model"
        :edit="edit"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import NavItemsAssigned from '@/components/NavItemsAssigned'
import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  components: {
    SingleModelLayout,
    TitleSummaryCard,
    NavItemsAssigned,
    LocaleSwitcher
  },

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      files: [],
      specOptions: {
        cpuProducts: [],
        gpuProducts: [],
        detailOptions: [
          { key: 'low', label: 'Low' },
          { key: 'med', label: 'Medium' },
          { key: 'high', label: 'High' },
          { key: 'ultra', label: 'Ultra/Max' }
        ],
        resolutionOptions: [
          { key: '1080p', label: '1080p' },
          { key: '1440p', label: '1440p' },
          { key: '4k', label: '4K' }
        ]
      },
      route: {
        apiEndpoint: 'menus',
        mediaEndpoint: 'menu',
        index: 'menus.index'
      }
    }
  },

  computed: {
    models: {
      get () {
        return this.model.items
      },
      set (value) {
        this.model.items = value
      }
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          with: ['items'],
          locale: this.$route.params.locale
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateOrderAndParentId (parent) {
      if (parent && parent.children) {
        for (var i = 0, l = parent.children.length; i < l; ++i) {
          var child = parent.children[i]
          child.order = i
          if (!child.parentId) child.parent_id = parent.id || null
          this.updateOrderAndParentId(child)
        }
      }
    },

    getAllItems (items) {
      let children = []
      return items.map(item => {
        if (item.children && item.children.length) {
          children = [...children, ...item.children]
        }
        return item
      }).concat(children.length ? this.getAllItems(children) : children)
    },

    saveModel () {
      const modelCopy = JSON.parse(JSON.stringify(this.model))
      modelCopy.items.map(item => this.updateOrderAndParentId(item))
      modelCopy.items = this.getAllItems(modelCopy.items)
      this.loading = true
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        ...modelCopy,
        locale: this.$route.params.locale
      })
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
