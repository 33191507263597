<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :list="list"
    :value="value"
    :disabled="!edit"
    @input="emitter"
  >
    <div class="item-group space-y-1" :key="el.id" v-for="el in realValue">
      <div class="py-2 px-2 bg-gray-200 rounded-sm flex justify-between items-center space-x-2">
        <div class="flex items-center space-x-2">
          <span v-if="edit" class="handle cursor-move px-2">
        <font-awesome-icon :icon="['fas', 'arrows-alt-v']" fa-fw />
        </span>
          <vue-input
            v-if="edit"
            v-model="el.label"
            placeholder="Label"
          />
          <span v-else>{{ el.label }}</span>
        </div>
        <div class="flex items-center space-x-2">
          <template v-if="edit">
            <vue-input
              v-model="el.slug"
              placeholder="Slug"
            />
            <vue-button
              text
              error
              xs
              :icon="['fas', 'trash']"
              @click.native="removeItem(el.id)"
            />
          </template>
          <span v-else>
                {{ el.slug }}
              </span>
        </div>
      </div>
      <nested-draggable-items class="item-sub" :list="el.children" :edit="edit" :remove-item="removeItem" />
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'nested-draggable-items',
  methods: {
    emitter (value) {
      this.model.items = value
      this.$emit('input', value)
    }
  },
  components: {
    draggable
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue () {
      return this.value ? this.value : this.list
    }
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    edit: {
      required: false,
      type: Boolean,
      default: false
    },
    model: {
      required: false,
      type: Object
    },
    removeItem: Function
  }
}
</script>
<style scoped>
.item-sub {
  margin: 0 0 0 1rem;
}
</style>
